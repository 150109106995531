import React from 'react'
import { Card as CardComponent } from 'theme-ui'
import { Layout, Stack, Main } from '@layout'
import CardList from '@components/CardList'
import Card from '@components/Card'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import AuthorExpanded from '@widgets/AuthorExpanded'
import NewsletterExpanded from '@widgets/NewsletterExpanded'
import { PostBody, PostComments, PostTagsShare } from '@widgets/Post'
//import { GatsbySeo } from 'gatsby-plugin-next-seo';


const Post = ({
  data: { post, tagCategoryPosts, tagPosts, categoryPosts },
  ...props
}) => {
  const relatedPosts = [
    ...(tagCategoryPosts ? tagCategoryPosts.nodes : []),
    ...(tagPosts ? tagPosts.nodes : []),
    ...(categoryPosts ? categoryPosts.nodes : [])
  ]
  const { pageContext: { services = {}, siteUrl } = {} } = props
  
  return (
    <Layout {...props}>
      <Seo {...post} siteUrl={siteUrl}/>
      {/*<GatsbySeo
      title='Find out more about Real Estate investment in Lagos, Abuja Nigeria and Dubai on our Blog.'
      description='Our Blog deep dives into all you need to know about Real Estate investment in Lagos, Abuja and Dubai.'
      openGraph={{
        title: 'Find out more about Real Estate investment in Lagos, Abuja Nigeria and Dubai on our Blog.',
        description: 'Our Blog deep dives into all you need to know about Real Estate investment in Lagos, Abuja and Dubai.',
        url: 'https://www.ownahome.ng/',
        type: 'article',
        article: {
          publishedTime: 'date',
          authors: [
            'post.author',
          ],
          section: 'Section I',
          tags: ['tags.name'],
        },
        images: [
          {
            url: 'https://images.ctfassets.net/abyiu1tn7a0f/3jZ2ne8tOyoN7s3Yem2Hqm/527c3403010c6a4bb4a0d121069cc6ee/home-hero.jpg?h=250',
            width: 850,
            height: 650,
            alt: 'Feel at Home',
          },
        ],
        site_name: 'Feel at home',
      }}
      twitter={{
        handle: '@owanhome_ng',
        site: '@ownahome_ng',
        cardType: 'summary_large_image',
      }}
    />*/}
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <Main>
          <Card {...post} variant='horizontal-hero' omitExcerpt />
        </Main>
      </Stack>
      <Divider />
      <Stack effectProps={{ fraction: 0 }}>
        <Main>
          <CardComponent variant='paper-lg'>
            <PostBody {...post} />
            <PostTagsShare {...post} location={props.location} />
          </CardComponent>
          <Divider />
          <AuthorExpanded author={post.author} />
          {services.disqus && (
            <>
              <Divider />
              <PostComments {...post} />
            </>
          )}
          <Divider />
          {post.category && (
            <CardList
              nodes={relatedPosts}
              variant={['horizontal-md']}
              columns={[1, 2, 3, 3]}
              limit={6}
              title='Related Posts'
              distinct
            />
          )}
          {services.mailchimp && (
            <>
              <Divider />
              <NewsletterExpanded simple />
            </>
          )}
        </Main>
      </Stack>
    </Layout>
  )
}

export default Post
